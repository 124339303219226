@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";
@import "/node_modules/primeflex/primeflex.css";
//@import "assets/np-styles/np-style1";
//@import "assets/np-styles/np-style2";

* {
  font-family: 'Montserrat', sans-serif;
}

$np-color: #03a9c1;

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

.mat-mdc-header-cell, .mdc-data-table__cell {
  font-family: 'Montserrat', sans-serif !important;
  color: #0A1825;
}

.mat-checkbox-frame {
  border-radius: 4px !important;
  border: 1px solid #00CFDE !important;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  border-radius: 4px !important;
  background-color: #00CFDE !important;
}

.mat-mdc-unelevated-button .mdc-button__label, .mat-mdc-raised-button .mdc-button__label {
  color: white;
}

.mat-step-header .mat-step-icon-selected, .mat-step-header .mat-step-icon-state-edit {
  background-color: #03a9c1 !important
}

.mat-mdc-form-field-flex {
  align-items: center !important;
}

.mdc-button {
  letter-spacing: 0 !important;
}

.mat-drawer-inner-container {
  overflow: hidden !important;
}

.mat-mdc-dialog-container .mdc-dialog__content, .mdc-data-table__cell, .mdc-list-group__subheader, .mdc-list-item__primary-text {
  font-family: 'Montserrat', sans-serif !important;
}

.mat-mdc-menu-panel.mat-mdc-menu-panel {
  min-width: 0 !important;
}

.mat-mdc-progress-spinner .mdc-circular-progress__determinate-circle, .mat-mdc-progress-spinner .mdc-circular-progress__indeterminate-circle-graphic {
  stroke: #03a9c1 !important;
}

.mat-mdc-form-field .mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  color: #00CFDE !important;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading, .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch, .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: #00CFDE !important;
}

.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full, .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #00CFDE !important;
}

.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after {
  color: #00CFDE !important;
}

.mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: #00CFDE !important;
}

.mat-mdc-list-option .mdc-radio .mdc-radio__native-control:enabled:checked + .mdc-radio__background .mdc-radio__outer-circle, .mat-mdc-list-option .mdc-radio .mdc-radio__native-control:enabled + .mdc-radio__background .mdc-radio__inner-circle {
  border-color: #03a9c1 !important;
}

.list-group-item.active {
  background-color: #03a9c1 !important;
}

.mat-mdc-raised-button:not(:disabled) {
  background-color: #03a9c1 !important;
}

.mat-mdc-option .mdc-list-item__primary-text {
  font-family: 'Montserrat', sans-serif !important;
}

.fontawesome-icon-custom {
  margin-right: 8px;
  width: 15px;
}

.np-color {
  color: $np-color;
}

.gap-container-2 {
  display: flex;
  flex-direction: row;
  gap: 2rem;
}

.gap-container-1 {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

#element-page-filters {
  .p-overlay-content {
    position: fixed !important;
  }
}

.p-tag {
  border-radius: 6px;
}

.np-tag {
  //background: #3B82F6;
  color: #ffffff;
  font-size: 0.75rem;
  font-weight: 700;
  padding: 0.25rem 0.4rem;
  border-radius: 6px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

